var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            " Quick first, previous, next, last, and page buttons for pagination control of another component (such as "
          )
        ]),
        _c("code", [_vm._v("<b-table>")]),
        _c("span", [_vm._v("or lists).")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c("b-pagination", {
            attrs: { "hide-goto-end-buttons": "", "total-rows": _vm.rows },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }