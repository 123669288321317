var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Button size" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Optionally change from the default button size by setting the"
          )
        ]),
        _c("code", [_vm._v("size")]),
        _c("span", [_vm._v("prop to either")]),
        _c("code", [_vm._v("'sm'")]),
        _c("span", [_vm._v("for smaller buttons or ")]),
        _c("code", [_vm._v("'lg'")]),
        _c("span", [_vm._v("for larger buttons.")])
      ]),
      _c(
        "div",
        [
          _c("h6", [_vm._v("Small")]),
          _c("b-pagination", {
            attrs: { "total-rows": _vm.rows, size: "sm" },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("h6", [_vm._v("Default")]),
          _c("b-pagination", {
            attrs: { "total-rows": _vm.rows },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2 demo-spacing-0" },
        [
          _c("h6", [_vm._v("Large")]),
          _c("b-pagination", {
            attrs: { "total-rows": _vm.rows, size: "lg" },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }