var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Goto first/last button type" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeGoto) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "If you prefer to have buttons with the first and last page number to go to the corresponding page, use the"
          )
        ]),
        _c("code", [_vm._v("first-number")]),
        _c("span", [_vm._v("and")]),
        _c("code", [_vm._v("last-number")]),
        _c("span", [_vm._v("props")]),
        _vm._v(". ")
      ]),
      _c(
        "div",
        [
          _c("h6", [_vm._v("Goto first button number")]),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              "first-number": ""
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("h6", [_vm._v("Goto last button number")]),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              "last-number": ""
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2 demo-spacing-0" },
        [
          _c("h6", [_vm._v("Goto first and last button number")]),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              "first-number": "",
              "last-number": ""
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }