var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Button content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeContent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("For a full list of all available slots see the")]),
        _c("code", [_vm._v("Slots")]),
        _c("span", [_vm._v("section below.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              "first-text": "First",
              "prev-text": "Prev",
              "next-text": "Next",
              align: "left",
              "last-text": "Last"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              "first-text": "⏮",
              "prev-text": "⏪",
              "next-text": "⏩",
              align: "left",
              "last-text": "⏭"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.rows,
              "per-page": _vm.perPage,
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "first-text",
                fn: function() {
                  return [
                    _c("span", { staticClass: "text-success" }, [
                      _vm._v("First")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("Prev")])
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v("Next")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "last-text",
                fn: function() {
                  return [
                    _c("span", { staticClass: "text-info" }, [_vm._v("Last")])
                  ]
                },
                proxy: true
              },
              {
                key: "ellipsis-text",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _c("b-spinner", { attrs: { small: "", type: "grow" } }),
                        _c("b-spinner", { attrs: { small: "", type: "grow" } })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "page",
                fn: function(ref) {
                  var page = ref.page
                  var active = ref.active
                  return [
                    active
                      ? _c("b", [_vm._v(_vm._s(page))])
                      : _c("i", [_vm._v(_vm._s(page))])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }