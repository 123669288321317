var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Pill style" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePill) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Easily switch to pill style buttons by setting the")
        ]),
        _c("code", [_vm._v("pills")]),
        _c("span", [_vm._v("prop.")])
      ]),
      _c(
        "div",
        [
          _c("h6", [_vm._v("Small Pills")]),
          _c("b-pagination", {
            attrs: { pills: "", "total-rows": _vm.rows, size: "sm" },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("h6", [_vm._v("Default Pills")]),
          _c("b-pagination", {
            attrs: { pills: "", "total-rows": _vm.rows },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2 demo-spacing-0" },
        [
          _c("h6", [_vm._v("Large Pills")]),
          _c("b-pagination", {
            attrs: { pills: "", "total-rows": _vm.rows, size: "lg" },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }