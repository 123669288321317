var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Color Option" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColorOption) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("To add pagination with different color option, use class")
        ]),
        _c("code", [_vm._v(".pagination-{colorName}")]),
        _c("span", [_vm._v("with ")]),
        _c("code", [_vm._v("<b-pagination>")]),
        _c("span", [_vm._v("components.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c("b-pagination", {
            staticClass: "pagination-success",
            attrs: {
              "total-rows": _vm.rows,
              "first-number": "",
              "last-number": ""
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "ChevronLeftIcon" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "ChevronRightIcon" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "pagination-danger mt-2",
            attrs: {
              "total-rows": _vm.rows,
              "first-number": "",
              "last-number": ""
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "ChevronLeftIcon" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "ChevronRightIcon" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          }),
          _c("b-pagination", {
            staticClass: "pagination-info mb-0",
            attrs: {
              "total-rows": _vm.rows,
              "first-number": "",
              "last-number": ""
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "ChevronLeftIcon" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "ChevronRightIcon" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }